this.just = this.just || {};
this.just.curves = (function (exports) {
'use strict';

var pi = Math.PI;
var tau = 2 * pi;
var epsilon = 0.0001;
var c1 = 1.70158;
var c2 = c1 * 1.525;
var c3 = c1 + 1;

var c = "cubic-bezier";
var s = "steps";
var ease = c + "(.25,.1,.25,1)";
var easeIn = c + "(.42,0,1,1)";
var easeInBack = c + "(.6,-.28,.735,.045)";
var easeInCirc = c + "(.6,.04,.98,.335)";
var easeInCubic = c + "(.55,.055,.675,.19)";
var easeInExpo = c + "(.95,.05,.795,.035)";
var easeInOut = c + "(.42,0,.58,1)";
var easeInOutBack = c + "(.68,-.55,.265,1.55)";
var easeInOutCirc = c + "(.785,.135,.15,.86)";
var easeInOutCubic = c + "(.645,.045,.355,1)";
var easeInOutExpo = c + "(1,0,0,1)";
var easeInOutQuad = c + "(.455,.03,.515,.955)";
var easeInOutQuart = c + "(.77,0,.175,1)";
var easeInOutQuint = c + "(.86,0,.07,1)";
var easeInOutSine = c + "(.445,.05,.55,.95)";
var easeInQuad = c + "(.55,.085,.68,.53)";
var easeInQuart = c + "(.895,.03,.685,.22)";
var easeInQuint = c + "(.755,.05,.855,.06)";
var easeInSine = c + "(.47,0,.745,.715)";
var easeOut = c + "(0,0,.58,1)";
var easeOutBack = c + "(.175,.885,.32,1.275)";
var easeOutCirc = c + "(.075,.82,.165,1)";
var easeOutCubic = c + "(.215,.61,.355,1)";
var easeOutExpo = c + "(.19,1,.22,1)";
var easeOutQuad = c + "(.25,.46,.45,.94)";
var easeOutQuart = c + "(.165,.84,.44,1)";
var easeOutQuint = c + "(.23,1,.32,1)";
var easeOutSine = c + "(.39,.575,.565,1)";
var elegantSlowStartEnd = c + "(.175,.885,.32,1.275)";
var linear = c + "(0,0,1,1)";
var stepEnd = s + "(1,0)";
var stepStart = s + "(1,1)";



var cssEasings = Object.freeze({
	ease: ease,
	easeIn: easeIn,
	easeInBack: easeInBack,
	easeInCirc: easeInCirc,
	easeInCubic: easeInCubic,
	easeInExpo: easeInExpo,
	easeInOut: easeInOut,
	easeInOutBack: easeInOutBack,
	easeInOutCirc: easeInOutCirc,
	easeInOutCubic: easeInOutCubic,
	easeInOutExpo: easeInOutExpo,
	easeInOutQuad: easeInOutQuad,
	easeInOutQuart: easeInOutQuart,
	easeInOutQuint: easeInOutQuint,
	easeInOutSine: easeInOutSine,
	easeInQuad: easeInQuad,
	easeInQuart: easeInQuart,
	easeInQuint: easeInQuint,
	easeInSine: easeInSine,
	easeOut: easeOut,
	easeOutBack: easeOutBack,
	easeOutCirc: easeOutCirc,
	easeOutCubic: easeOutCubic,
	easeOutExpo: easeOutExpo,
	easeOutQuad: easeOutQuad,
	easeOutQuart: easeOutQuart,
	easeOutQuint: easeOutQuint,
	easeOutSine: easeOutSine,
	elegantSlowStartEnd: elegantSlowStartEnd,
	linear: linear,
	stepEnd: stepEnd,
	stepStart: stepStart
});

var steps = function (count, pos) {
    var q = count / 1;
    var p = pos === 'end'
        ? 0 : pos === 'start'
        ? 1 : pos || 0;
    return function (x) { return x >= 1 ? 1 : (p * q + x) - (p * q + x) % q; };
};

var bezier = function (n1, n2, t) {
    return 3 * n1 * (1 - t) * (1 - t) * t + 3 * n2 * (1 - t) * t * t + t * t * t;
};
var cubicBezier = function (p0, p1, p2, p3) {
    if (p0 < 0 || p0 > 1 || p2 < 0 || p2 > 1) {
        return function (x) { return x; };
    }
    return function (x) {
        if (x === 0 || x === 1) {
            return x;
        }
        var start = 0;
        var end = 1;
        var limit = 19;
        do {
            var mid = (start + end) * .5;
            var xEst = bezier(p0, p2, mid);
            if (abs(x - xEst) < epsilon) {
                return bezier(p1, p3, mid);
            }
            if (xEst < x) {
                start = mid;
            }
            else {
                end = mid;
            }
        } while (--limit);
        return x;
    };
};

var camelCaseRegex = /([a-z])[- ]([a-z])/ig;
var cssFunctionRegex = /^([a-z-]+)\(([^\)]+)\)$/i;
var cssEasings$1 = { ease: ease, easeIn: easeIn, easeOut: easeOut, easeInOut: easeInOut, stepStart: stepStart, stepEnd: stepEnd, linear: linear };
var camelCaseMatcher = function (match, p1, p2) { return p1 + p2.toUpperCase(); };
var toCamelCase = function (value) { return typeof value === 'string'
    ? value.replace(camelCaseRegex, camelCaseMatcher) : ''; };
var find = function (nameOrCssFunction) {
    var easingName = toCamelCase(nameOrCssFunction);
    var easing = cssEasings$1[easingName] || nameOrCssFunction;
    var matches = cssFunctionRegex.exec(easing);
    if (!matches) {
        throw new Error('css parse error');
    }
    return [matches[1]].concat(matches[2].split(','));
};
var cssFunction = function (easingString) {
    var p = find(easingString);
    var fnName = p[0];
    if (fnName === 'steps') {
        return steps(+p[1], p[2]);
    }
    if (fnName === 'cubic-bezier') {
        return cubicBezier(+p[1], +p[2], +p[3], +p[4]);
    }
    throw new Error('css parse error');
};

var abs = Math.abs;


var cos = Math.cos;
var pow = Math.pow;
var sin = Math.sin;
var sqrt = Math.sqrt;

var ease$1 = cubicBezier(.25, .1, .25, .1);

var easeIn$1 = cubicBezier(.42, 0, 1, 1);

var easeInBack$1 = function (x) { return c3 * x * x * x - c1 * x * x; };

var easeInBounce = function (x) { return 1 - easeOutBounce(1 - x); };

var easeInCirc$1 = function (x) { return 1 - sqrt(1 - (x * x)); };

var easeInCubic$1 = function (x) { return x * x * x; };

var easeInElastic = function (n) {
    return !n || n === 1 ? n : -1 * sin((n - 1.1) * tau * 2.5) * pow(2, 10 * (n - 1));
};

var easeInExpo$1 = function (x) { return x === 0 ? 0 : pow(2, 10 * x - 10); };

var easeInOut$1 = cubicBezier(.42, 0, .58, 1);

var easeInOutBack$1 = function (x) { return x < 0.5
    ? (pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2
    : (pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2; };

var easeInOutBounce = function (x) { return x < 0.5
    ? (1 - easeOutBounce(1 - 2 * x)) / 2
    : (1 + easeOutBounce(2 * x - 1)) / 2; };

var easeInOutCirc$1 = function (x) { return x < 0.5
    ? (1 - sqrt(1 - pow(2 * x, 2))) / 2
    : (sqrt(1 - pow(-2 * x + 2, 2)) + 1) / 2; };

var easeInOutCubic$1 = function (x) { return x < 0.5
    ? 4 * x * x * x
    : 1 - pow(-2 * x + 2, 3) / 2; };

var easeInOutElastic = function (n) {
    if (!n || n === 1)
        return n;
    n *= 2;
    if (n < 1) {
        return -0.5 * (pow(2, 10 * (n - 1)) * sin((n - 1.1) * tau / .4));
    }
    return pow(2, -10 * (n - 1)) * sin((n - 1.1) * tau / .4) * .5 + 1;
};

var easeInOutExpo$1 = function (x) { return x === 0
    ? 0 : x === 1
    ? 1 : x < 0.5
    ? pow(2, 20 * x - 10) / 2
    : (2 - pow(2, -20 * x + 10)) / 2; };

var easeInOutQuad$1 = function (x) { return x < 0.5 ?
    2 * x * x :
    1 - pow(-2 * x + 2, 2) / 2; };

var easeInOutQuart$1 = function (x) { return x < 0.5
    ? 8 * x * x * x * x
    : 1 - pow(-2 * x + 2, 4) / 2; };

var easeInOutQuint$1 = function (x) { return x < 0.5
    ? 16 * x * x * x * x * x
    : 1 - pow(-2 * x + 2, 5) / 2; };

var easeInOutSine$1 = function (x) { return -(cos(pi * x) - 1) / 2; };

var easeInQuad$1 = function (x) { return x * x; };

var easeInQuart$1 = function (x) { return x * x * x * x; };

var easeInQuint$1 = function (x) { return x * x * x * x * x; };

var easeInSine$1 = function (x) { return 1 - cos(x * pi / 2); };

var easeOut$1 = cubicBezier(0, 0, .58, 1);

var easeOutBack$1 = function (x) { return 1 + c3 * pow(x - 1, 3) + c1 * pow(x - 1, 2); };

var easeOutBounce = function (x) {
    var n1 = 7.5625;
    var d1 = 2.75;
    return x < 1 / d1
        ? n1 * x * x
        : x < 2 / d1
            ? n1 * (x -= (1.5 / d1)) * x + .75
            : x < 2.5 / d1
                ? n1 * (x -= (2.25 / d1)) * x + .9375
                : n1 * (x -= (2.625 / d1)) * x + .984375;
};

var easeOutCirc$1 = function (x) { return sqrt(1 - ((x - 1) * (x - 1))); };

var easeOutCubic$1 = function (x) { return 1 - pow(1 - x, 3); };

var easeOutElastic = function (n) {
    if (!n || n === 1)
        return n;
    var s, a = 0.1, p = 0.4;
    if (!a || a < 1) {
        a = 1;
        s = p / 4;
    }
    else
        s = p * Math.asin(1 / a) / tau;
    return (a * pow(2, -10 * n) * sin((n - s) * (tau) / p) + 1);
};

var easeOutExpo$1 = function (x) { return x === 1 ? 1 : 1 - pow(2, -10 * x); };

var easeOutQuad$1 = function (x) { return 1 - (1 - x) * (1 - x); };

var easeOutQuart$1 = function (x) { return 1 - pow(1 - x, 4); };

var easeOutQuint$1 = function (x) { return 1 - pow(1 - x, 5); };

var easeOutSine$1 = function (x) { return sin(x * pi / 2); };

var linear$1 = function (x) { return x; };

var stepEnd$1 = steps(1, 0);

var stepStart$1 = steps(1, 1);

exports.css = cssEasings;
exports.cssFunction = cssFunction;
exports.cubicBezier = cubicBezier;
exports.steps = steps;
exports.ease = ease$1;
exports.easeIn = easeIn$1;
exports.easeInBack = easeInBack$1;
exports.easeInBounce = easeInBounce;
exports.easeInCirc = easeInCirc$1;
exports.easeInCubic = easeInCubic$1;
exports.easeInElastic = easeInElastic;
exports.easeInExpo = easeInExpo$1;
exports.easeInOut = easeInOut$1;
exports.easeInOutBack = easeInOutBack$1;
exports.easeInOutBounce = easeInOutBounce;
exports.easeInOutCirc = easeInOutCirc$1;
exports.easeInOutCubic = easeInOutCubic$1;
exports.easeInOutElastic = easeInOutElastic;
exports.easeInOutExpo = easeInOutExpo$1;
exports.easeInOutQuad = easeInOutQuad$1;
exports.easeInOutQuart = easeInOutQuart$1;
exports.easeInOutQuint = easeInOutQuint$1;
exports.easeInOutSine = easeInOutSine$1;
exports.easeInQuad = easeInQuad$1;
exports.easeInQuart = easeInQuart$1;
exports.easeInQuint = easeInQuint$1;
exports.easeInSine = easeInSine$1;
exports.easeOut = easeOut$1;
exports.easeOutBack = easeOutBack$1;
exports.easeOutBounce = easeOutBounce;
exports.easeOutCirc = easeOutCirc$1;
exports.easeOutCubic = easeOutCubic$1;
exports.easeOutElastic = easeOutElastic;
exports.easeOutExpo = easeOutExpo$1;
exports.easeOutQuad = easeOutQuad$1;
exports.easeOutQuart = easeOutQuart$1;
exports.easeOutQuint = easeOutQuint$1;
exports.easeOutSine = easeOutSine$1;
exports.linear = linear$1;
exports.stepEnd = stepEnd$1;
exports.stepStart = stepStart$1;

return exports;

}({}));
